import React from "react";
import ProjectComponent from "../components/ProjectComponent";
import ProjectCard from "../components/ProjectCard";

export default function Projects({ sectionData }) {
  const projects = sectionData;

  return (
    <div
      className="py-8 lg:py-24  flex items-center container mx-auto "
      id="projects">
      <div className="gap-y-8 w-full ">
        {/*top projects section */}
        <div className="align-middle font-secondary text-lg ">
          <h1 className="text-[30px] font-bold leading-[0.8] lg:text-[50px]  my-4 flex items-baseline">
            <p className="mr-6 text-accent text-lg">03.</p> Projects
          </h1>
          <div className="mx-auto">
            <div className="my-10 text-gray text-[14px]">
              {projects &&
                projects
                  .filter((project) => project.is_major)
                  .map((project, index) => {
                    const isEvenIndex = index % 2 === 0;
                    return (
                      <ProjectComponent
                        key={project.id}
                        isEvenIndex={isEvenIndex}
                        project={project}
                        index={index}
                      />
                    );
                  })}
            </div>
          </div>
        </div>
        {/* project grid */}

        <div className="text-[14px] py-10 ">
          {/* <div className="flex items-center gap-4">
            <h1 className="text-[30px] font-bold leading-[0.8] lg:text-[32px]  my-4 flex items-baseline ">
              Other Notable Projects
            </h1>
            <div className="w-[500px] h-[0.1px] bg-white hidden lg:block" />
          </div> */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 py-6">
            {projects &&
              projects
                .filter((project) => !project.is_major)
                .map((project, index) => {
                  return <ProjectCard project={project} index={index} />;
                })}
          </div>
        </div>
      </div>
    </div>
  );
}
