import React from "react";
import { FaExternalLinkAlt, FaGithub, FaRegFolder } from "react-icons/fa";

export default function ProjectCard({ project }) {
  // Function to truncate the description to a certain number of characters
  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };

  return (
    project && (
      <div className="flex-col bg-alternate p-8 rounded-md">
        <div className="flex justify-between ">
          <a
            href={project.github ? project.github : project.preview}
            target="_blank"
            rel="noreferrer">
            <FaRegFolder className="text-[35px] font-extralight text-accent hover:text-white" />
          </a>
          <div className="flex gap-4 align-middle items-center">
            {project.github && (
              <a
                href={project.github ? project.github : null}
                target="_blank"
                rel="noreferrer">
                <FaGithub className="text-[20px] text-gray hover:text-white font-extralight" />
              </a>
            )}
            {project.preview && (
              <a
                href={project.preview ? project.preview : null}
                target="_blank"
                rel="noreferrer">
                <FaExternalLinkAlt className="text-[20px] text-gray hover:text-white font-extralight" />
              </a>
            )}
          </div>
        </div>
        <div className="py-8">
          <a href={project.preview} className="text-2xl hover:text-accent">
            {project.title}
          </a>
          <div className="text-gray py-2">
            {truncateString(project.description, 150)}
          </div>
        </div>
        <div className="flex text-[11px] gap-2 text-gray">
          <div className="flex flex-wrap gap-2">
            {project.tech &&
              project.tech.map((tech, index) => {
                return (
                  <p
                    className="hover:text-white cursor-pointer hover:text-[16px]"
                    key={index}>
                    {tech}
                  </p>
                );
              })}
          </div>
        </div>
      </div>
    )
  );
}
