import React, { useState } from "react";
import {
  FaGithub,
  FaLinkedinIn,
  FaMedium,
  FaStackOverflow,
  FaTwitter,
} from "react-icons/fa";

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(formData);
    // Reset form fields
    setFormData({
      name: "",
      email: "",
      message: "",
    });
  };

  return (
    <div className="section container  mx-auto " id="contact">
      <div className="flex flex-col gap-y-8 gap-x-14 lg:flex-row  w-full">
        {/* contact second part */}
        <div className="text-[14px] w-full lg:w-1/2 text-left content-between ">
          <div className="h-1/4 ">
            <h1 className="text-[30px] font-bold leading-[0.8] lg:text-[50px]  my-4 flex items-baseline">
              <p className="mr-6 text-accent text-lg self-baseline">04.</p> Lets
              Talk.
            </h1>
          </div>
          <div className="md:ml-14 h-3/4">
            <h1 className="mt-6 font-bold">EMAIL / CALL</h1>
            <p>
              <a href="mailto:judefabianose@gmail.com" className="text-gray">
                judefabianose@gmail.com
              </a>
            </p>
            <p>
              <a href="tel:+254791808163" className="text-gray">
                +254791808163 || +254797544152
              </a>
            </p>
            <h1 className="mt-6 font-bold">FIND ME</h1>
            <p className="text-gray">Nairobi,Kenya || Eldoret, Kenya</p>

            <h1 className="mt-6 font-bold">SOCIALS</h1>
            <div className="flex text-[20px] gap-4 py-2 max-w-max  lg:mx-0 text-gray ">
              <a
                href="https://www.linkedin.com/in/jude-fabiano-2a7786167/"
                target="_blank"
                rel="noreferrer">
                <FaLinkedinIn className="hover:text-secondary" />
              </a>
              <a
                href="https://github.com/Judefabi"
                target="_blank"
                rel="noreferrer">
                <FaGithub className="hover:text-secondary" />
              </a>
              <a
                href="https://medium.com/@judefabiano99"
                target="_blank"
                rel="noreferrer">
                <FaMedium className="hover:text-secondary" />
              </a>
              <a
                href="https://stackoverflow.com/users/12557742/judefabi"
                target="_blank"
                rel="noreferrer">
                <FaStackOverflow className="hover:text-secondary" />
              </a>

              <a
                href=" https://twitter.com/I_JFabiano"
                target="_blank"
                rel="noreferrer">
                <FaTwitter className="hover:text-secondary" />
              </a>
            </div>
          </div>
        </div>
        <div className="align-middle font-secondary text-lg lg:w-1/2 ">
          {/*contact first part  */}

          <div className=" text-accent text-[14px]">
            <form onSubmit={handleSubmit} className="space-y-4 w-full ">
              <div>
                <label
                  htmlFor="name"
                  className="block mb-1 font-medium text-white">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-primary border-b-secondary border-b-2 focus:outline-none bg-primary focus:border-b-white"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block mb-1 font-medium text-white">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-primary border-b-secondary border-b-2 focus:outline-none bg-primary focus:border-b-white"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="message"
                  className="block mb-1 font-medium text-white">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows="4"
                  className="w-full px-3 py-2 border border-primary border-b-secondary border-b-2 focus:outline-none bg-primary focus:border-b-white"
                  required></textarea>
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full px-4 py-2 bg-accent text-primary rounded-md hover:bg-accent-dark focus:outline-none focus:bg-accent-dark">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
