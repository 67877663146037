import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import {
  HiHome,
  HiUser,
  HiViewColumns,
  HiRectangleGroup,
  HiEnvelope,
} from "react-icons/hi2";
import { FaBars } from "react-icons/fa";

// assets

export default function Navigation() {
  const navigation = [
    { name: "Home", href: "home", current: true, icon: <HiHome /> },
    { name: "About", href: "about", current: false, icon: <HiUser /> },
    {
      name: "Experience",
      href: "Experience",
      current: false,
      icon: <HiRectangleGroup />,
    },
    {
      name: "Projects",
      href: "projects",
      current: false,
      icon: <HiViewColumns />,
    },
    { name: "Contact", href: "contact", current: false, icon: <HiEnvelope /> },
  ];

  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className={`fixed w-full h-[80px] flex justify-evenly items-center bg-primary mx-auto pt-4 pb-2 ${
        isScrolled ? "bg-opacity-100" : "bg-transparent"
      } z-20`}>
      <div className="container mx-auto flex items-center justify-between">
        <div className="flex">
          <a href="#home">
            <h1 className=" text-accent  text-3xl font-logo">MR. FABIANO</h1>
          </a>
          {/* desktop menu */}
          <div className="hidden md:flex items-center space-x-4 px-8">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                offset={item.href === "home" ? -200 : -50}
                smooth={true}
                activeClass="active"
                className={
                  "cursor-pointer text-white font-primary text-sm uppercase hover:text-accent"
                }>
                {item.name}
              </Link>
            ))}
          </div>
        </div>

        <a
          className="content-center space-x-4 hidden md:block  btn-sm hover:bg-white hover:text-alternate border font-bold"
          href="https://firebasestorage.googleapis.com/v0/b/jude-7dcbc.appspot.com/o/Jude%20Fabiano%20Imoli_CV_Fullstack_u.pdf?alt=media&token=9d07e350-61be-4f2b-a71c-1dbe126f41ca"
          target="_blank"
          rel="noreferrer">
          DOWNLOAD RESUME
        </a>

        {/* mobile menu */}
        <div
          onClick={toggleMenu}
          className="md:hidden self-center cursor-pointer">
          <FaBars className=" text-3xl mr-6 hover:text-accent" />
        </div>
      </div>
      {isOpen && (
        <div className="absolute top-0 right-0 w-full h-screen bg-primary flex flex-col justify-center items-center">
          <button
            onClick={toggleMenu}
            className="text-4xl font-bold absolute top-5 right-10 hover:text-gray">
            X
          </button>
          {navigation.map((item) => (
            <Link
              onClick={toggleMenu}
              key={item.name}
              to={item.href}
              smooth={true}
              activeClass="active"
              className={
                "py-6 font-primary  text-2xl cursor-pointer hover:text-gray"
              }>
              {item.name}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}
