import React from "react";
import { FaRegCopyright } from "react-icons/fa";

export default function Footer() {
  const year = new Date().toLocaleDateString(undefined, { year: "numeric" });

  return (
    <div className="md:flex text-sm font-thin gap-4 p-8 text-white mx-auto container md:items-center justify-end content-center">
      <div className="">
        <a
          href="https://github.com/Judefabi"
          className="flex gap-2 md:justify-end hover:font-normal items-center ">
          <FaRegCopyright />
          {year}. Built by Jude Fabiano
        </a>
        <div className="flex justify-end gap-2">
          Project Section Design inspired by{" "}
          <a
            href="https://brittanychiang.com/"
            target="_blank"
            rel="noreferrer"
            className="hover:text-accent hover:font-normal">
            Britanny Chiang
          </a>
        </div>
        <a
          className="flex md:justify-end hover:font-normal items-center "
          href="https://visitcount.itsvg.in">
          <img
            src="https://visitcount.itsvg.in/api?id=judefabiano&label=Hey%20Visitor&color=0&icon=2&pretty=true"
            alt="visit count"
          />
        </a>
      </div>
    </div>
  );
}
