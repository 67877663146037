import React, { useEffect, useState } from "react";
import { BrowserRouter as Router} from "react-router-dom";
import { motion } from "framer-motion";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Experience from "./pages/Experience";
import { fetchDataSections, fetchMainDatabySection } from "./firebaseFunctions";
import LoaderScreen from "./components/LoaderScreen";

export default function App() {
  const [homeSection, setHomeSectionData] = useState({});
  const [aboutSection, setAboutSectionData] = useState({});
  const [experienceSection, setExperienceSectionData] = useState([]);
  const [projectSection, setProjectSectionData] = useState([]);
  const [loading, setLoading] = useState(true); // Initialize loading state

  useEffect(() => {
    async function fetchData() {
      try {
        // Fetch data for each section
        const homeData = await fetchMainDatabySection("homeSection");
        const aboutData = await fetchMainDatabySection("aboutSection");
        const experienceData = await fetchDataSections("experienceSection");
        const projectsData = await fetchDataSections("projectsSection");

        // Update state with fetched data
        setHomeSectionData(homeData);
        setAboutSectionData(aboutData);
        setExperienceSectionData(experienceData);
        setProjectSectionData(projectsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        // Set loading state to false once data fetching is complete
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="overflow-y-hidden bg-primary">
      <Router>
        {/* Conditionally render Loader component while data is loading */}
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1, ease: "easeOut" }}>
          {loading ? (
            <LoaderScreen />
          ) : (
            <>
              <Navigation />
              <Home sectionData={homeSection} />
              <About sectionData={aboutSection} />
              <Experience sectionData={experienceSection} />
              <Projects sectionData={projectSection} />
              <Contact />
              {/* <Routes>
              <Route path="/project/:id" element={<ProjectDetails />} />
            </Routes> */}
              <Footer />
            </>
          )}
        </motion.div>
      </Router>
    </div>
  );
}
