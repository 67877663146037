import React from "react";
import { motion } from "framer-motion";
import { Bars } from "react-loader-spinner";

const LoaderScreen = () => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 1, ease: "easeOut" }} // Transition duration
      className="flex justify-center items-center h-screen">
      <Bars
        height="80"
        width="80"
        color="#59E3C5"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </motion.div>
  );
};

export default LoaderScreen;
