import React from "react";
import { FaExternalLinkAlt, FaGithub } from "react-icons/fa";

export default function ProjectComponent({ project, isEvenIndex, index }) {
  console.log("project", project.image);
  return (
    project && (
      <div className=" text-gray text-[14px]">
        <div
          key={index}
          className={` my-20 flex items-center flex-col ${
            isEvenIndex ? "md:flex-row" : "md:flex-row-reverse"
          } w-full`}>
          {/* For mobile screens, set the image as a background */}
          <div className="md:hidden relative w-full min-h-[90vh] rounded-lg overflow-hidden ">
            <div
              className="absolute inset-0 bg-cover bg-center mix-blend-darken"
              style={{
                backgroundImage: `url(${"https://images.unsplash.com/photo-1565106430482-8f6e74349ca1?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"})`,
                zIndex: 1,
              }}></div>
            <div
              className={`p-10 bg-primary bg-opacity-40 absolute inset-0 flex flex-col justify-center md:w-full md:px-20 text-white z-10`}>
              <p className="text-sm text-accent font-primary">
                Featured Projects
              </p>
              <h1 className="text-white text-3xl font-bold mb-4">
                {project.title}
              </h1>
              <p className="text-white shadow-sm mb-4">{project.description}</p>
              <div className="flex text-[12px] flex-wrap">
                {project.tech.map((tech, index) => (
                  <div className="mr-2" key={index}>
                    .{tech}
                  </div>
                ))}
              </div>
              <div className="flex gap-x-10 py-4">
                {project.github && (
                  <a href={project.github} className="hover:text-accent">
                    <FaGithub className="text-xl " />
                  </a>
                )}
                {project.preview && (
                  <a href={project.preview} className="hover:text-accent">
                    <FaExternalLinkAlt className="text-xl " />
                  </a>
                )}
              </div>
            </div>
          </div>

          {/* For larger screens, display the image */}
          {/* <div className="bg-accent bg-opacity-40 hover:bg-white hover:bg-opacity-0 w-full">
            <img
              className="h-[50vh] md:w-[200vh] hidden md:block object-cover duration-300  mix-blend-overlay hover:mix-blend-color "
              src={project.image}
              alt={project.title}
            />
          </div> */}
          <div className="bg-accent bg-opacity-40 w-full">
            <img
              className="h-[50vh] md:w-[200vh] hidden md:block object-cover duration-300 filter mix-blend-overlay hover:mix-blend-normal"
              src={project.image}
              alt={project.title}
            />
          </div>

          <div
            className={`hidden md:block md:w-full ${
              isEvenIndex ? "md:-ml-20" : "md:-mr-20"
            } ${
              isEvenIndex ? "md:text-right" : "md:text-left"
            } opacity-100 self-center z-10`}>
            <p className="text-sm text-accent font-primary">
              Featured Projects
            </p>
            <h1 className="text-white text-3xl font-bold">{project.title}</h1>
            <p className=" bg-alternate shadow-primary shadow-sm w-full p-4 my-2 rounded-md">
              {project.description}
            </p>
            <div
              className={`flex flex-wrap text-[12px] ${
                isEvenIndex ? "md:justify-end" : "md:justify-start"
              }  `}>
              {project.tech.map((tech, index) => (
                <div className="mr-2" key={index}>
                  .{tech}
                </div>
              ))}
            </div>
            <div
              className={`flex gap-x-10 py-4 text-white  ${
                isEvenIndex ? "md:justify-end" : "md:justify-start"
              }`}>
              {project.github && (
                <a href={project.github} className="hover:text-accent">
                  <FaGithub className="text-xl " />
                </a>
              )}
              {project.preview && (
                <a href={project.preview} className="hover:text-accent">
                  <FaExternalLinkAlt className="text-xl " />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
}
