import { collection, getDocs } from "firebase/firestore/lite";
import { firestoreDb } from "./firebase";

export async function fetchDataBySection(id, sectionName) {
  console.log("fetchDataBySection called", id, sectionName);
  try {
    const collectionRef = collection(firestoreDb, "jude", id, sectionName);

    const querySnapshot = await getDocs(collectionRef);
    const data = [];
    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });

    console.log("Filtered array", data);
    return data;
  } catch (error) {
    console.error(`Error fetching data:`, error);
  }
}

export async function fetchDataSections(sectionName) {
  try {
    const q = collection(firestoreDb, "jude");
    const querySnapshot = await getDocs(q);
    // Array to store fetched data
    let data;

    // Fetch data for each document in the collection
    for (const doc of querySnapshot.docs) {
      console.log("Doc Id", doc.id);
      // Await the result of fetchDataBySection
      const sectionData = await fetchDataBySection(doc.id, sectionName);
      // pass the fetched array into data variable
      data = sectionData;
    }

    console.log("Data for all sections", data);
    return data;
  } catch (error) {
    console.error(`Error fetching data:`, error);
  }
}

export async function fetchMainDatabySection(sectionName) {
  try {
    const collectionRef = collection(firestoreDb, "jude");

    const querySnapshot = await getDocs(collectionRef);
    let sectionData = {};
    querySnapshot.forEach((doc) => {
      if (doc.data()[sectionName]) {
        sectionData = doc.data()[sectionName];
      }
    });
    console.log("Section Data", sectionData);
    return sectionData;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}
