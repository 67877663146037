import React from "react";
import {
  FaGithub,
  FaLinkedinIn,
  FaMedium,
  FaStackOverflow,
  FaTwitter,
} from "react-icons/fa";

export default function Home({ sectionData }) {
  const homeData = sectionData;

  return (
    <section
      className="min-h-[90vh] lg:min-h-[98vh] h-screen flex items-center relative bg-home bg-no-repeat"
      id="home">
      {/* <ScrollIndicator /> */}
      <div className="absolute inset-0 bg-gradient-to-r from-[#0a192f] via-[#0a192f] to-transparent"></div>
      <div className="container mx-auto relative z-10">
        <div className="flex flex-col gap-y-8 lg:flex-row lg:items-center">
          <div className="align-middle gap-y-8 flex-1 text-center font-secondary lg:text-left">
            {/* text part */}
            <p className="py-6 text-gray text-[16px]">Hi, my name is</p>
            <h1 className="text-[42px] font-bold leading-[0.8] lg:text-[80px] uppercase">
              {homeData?.name || "IMOLI FABIANO JUDE"}
            </h1>

            <p className="mb-8 max-w-lg mx-auto lg:mx-0 font-primary text-white text-[16px] md:text-sm py-4">
              {homeData?.shortDescription ||
                "Voluptate do cupidatat dolore tempor aliqua aute. Nulla pariatur consequat officia"}
            </p>
            <div className="flex max-w-max gap-x-6 items-center mb-12 mx-auto lg:mx-0">
              <div className="bg-accent w-[50px] h-[1px]"></div>
              <a
                href="tel:+254791808163"
                className="text-accent font-normal hover:text-white text-sm">
                CALL ME
              </a>
              {/* <a href="#" className="text-gradient btn-link">
                My Work
              </a> */}
            </div>
            <div className="flex text-[20px] gap-x-6 max-w-max mx-auto lg:mx-0">
              <a
                href="https://www.linkedin.com/in/jude-fabiano-2a7786167/"
                target="_blank"
                rel="noreferrer">
                <FaLinkedinIn className="hover:text-secondary" />
              </a>
              <a
                href="https://github.com/Judefabi"
                target="_blank"
                rel="noreferrer">
                <FaGithub className="hover:text-secondary" />
              </a>
              <a
                href="https://medium.com/@judefabiano99"
                target="_blank"
                rel="noreferrer">
                <FaMedium className="hover:text-secondary" />
              </a>
              <a
                href="https://stackoverflow.com/users/12557742/judefabi"
                target="_blank"
                rel="noreferrer">
                <FaStackOverflow className="hover:text-secondary" />
              </a>

              <a
                href=" https://twitter.com/I_JFabiano"
                target="_blank"
                rel="noreferrer">
                <FaTwitter className="hover:text-secondary" />
              </a>
            </div>
          </div>
          {/* image part */}
          <div>{/* <img src={avatar} /> */}</div>
        </div>
      </div>
    </section>
  );
}
