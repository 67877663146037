import React, { useState } from "react";

export default function Experience({ sectionData }) {
  console.log("Experience Data", sectionData);
  const workExperience = sectionData;

  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <div
      id="Experience"
      className="section container  mx-auto my-20 items-start">
      <div className="flex flex-col gap-y-4 gap-x-2 lg:flex-row w-full">
        {/* experience navigation */}
        <div className="w-full lg:w-1/3 font-secondary text-lg ">
          <div className="w-full lg:w-1/4">
            <h1 className="text-[30px] font-bold leading-[0.8] lg:text-[50px] my-4 flex items-baseline">
              <p className="mr-6 text-accent text-lg ">02.</p> Experience
            </h1>
          </div>
          <div className="grid grid-cols-auto-fit max-w-max gap-x-6 lg:mx-0  ">
            {workExperience &&
              workExperience.map((work, index) => (
                <button
                  onClick={() => setCurrentIndex(index)}
                  key={work.company}
                  className={`text-left ${
                    currentIndex === index ? "text-accent" : "text-gray"
                  } font-normal text-sm px-6 py-4 border-l-2 ${
                    currentIndex === index ? "border-accent" : "border-gray"
                  }   hover:bg-hover active:text-accent active:bg-none`}>
                  {work.company}
                </button>
              ))}
          </div>
        </div>
        {/* experience details part */}
        <div className="w-full lg:w-2/3 text-[16px]">
          <div className="flex gap-2">
            <h2 className="text-lg font-bold mb-2">
              {workExperience[currentIndex]?.title}
            </h2>
            <p className="text-gray mb-2">
              @
              <a
                href={workExperience[currentIndex]?.company_url}
                className="no-underline hover:underline text-accent">
                {workExperience[currentIndex]?.company}
              </a>
            </p>
          </div>
          <p className="text-gray mb-2 text-sm italic">
            {workExperience[currentIndex]?.period.start
              .toDate()
              .toLocaleDateString()}{" "}
            -{" "}
            {workExperience[currentIndex]?.period.end !== null &&
            workExperience[currentIndex]?.period.end !== undefined
              ? workExperience[currentIndex]?.period.end
                  .toDate()
                  .toLocaleDateString()
              : "Current"}
          </p>
          <ul class="overflow-y-auto list-inside">
            {workExperience[currentIndex]?.contributions &&
              workExperience[currentIndex].contributions.map((item, index) => (
                <li key={index} class="text-[14px] mt-2 space-y-1 list-disc ">
                  {/* <FaCaretRight class="text-accent bg-white" /> */}
                  {item}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
