import React from "react";
import { FaCaretRight } from "react-icons/fa";

export default function About({ sectionData }) {
  const aboutSection = sectionData;

  return (
    <div className="section  container  mx-auto " id="about">
      <div className="flex flex-col gap-y-8 gap-x-14 lg:flex-row">
        <div className="align-middle font-secondary text-lg ">
          {/*about first part  */}
          <h1 className="text-[30px] font-bold leading-[0.8] lg:text-[50px]  my-4 flex items-baseline">
            <p className="mr-6 text-accent text-lg">01.</p> About
          </h1>
          <p className="text-gray text-[14px]">
            &lt;p&gt; {aboutSection?.aboutShort || ""} &lt;/p&gt;
          </p>
          <div className="flex my-8 max-w-max gap-x-6 items-center mb-12 lg:mx-0">
            <div className="bg-accent w-[50px] h-[1px]"></div>{" "}
            <a
              href="https://firebasestorage.googleapis.com/v0/b/jude-7dcbc.appspot.com/o/JUDE%20FABIANO%20IMOLI_CV_Fullstack.pdf?alt=media&token=300456d4-a977-4c1d-9bcd-96a69bec17e6"
              target="_blank"
              rel="noreferrer"
              className="text-accent font-normal hover:text-white uppercase text-sm">
              Download Resume
            </a>
            {/* <a href="#" className="text-gradient btn-link">
                My Work
              </a> */}
          </div>
        </div>
        <div className="text-[14px]">
          {/* about second part */}
          {aboutSection?.aboutMain || ""}
          <div className="grid grid-cols-2 lg:grid-cols-3 ">
            {aboutSection.aboutTech &&
              aboutSection.aboutTech.map((tech) => (
                <div key={tech} className="flex items-center ">
                  <FaCaretRight className="text-sm text-accent" />
                  {/* {language.icon} */}
                  <span className="ml-2 text-gray text-[12px] hover:text-[14px] hover:text-white cursor-pointer">
                    {tech}
                  </span>
                </div>
              ))}
          </div>
          {aboutSection?.aboutPartingShot || ""}
        </div>
      </div>
    </div>
  );
}
